import * as React from 'react';
import { useTranslation } from 'react-i18next';

const Error = (
  props: { readonly display: boolean } & React.HTMLAttributes<HTMLElement>,
) => {
  const { t } = useTranslation();

  return props.display ? (
    <div>
      <h2 className={'c-gray fs-32'}>{t("Secret does not exist")}</h2>
      <p className="c-light-gray fs-16">
      {t("It might be caused by any of these reasons.")}
      </p>
      <h4 className={'c-gray fs-20'}>{t("Opened before")}</h4>
      <p className={'c-light-gray fs-16'}>
        {t("A secret could be restricted to Magebit employees only. It might be lost because the sender clicked this link before you viewed it.")}
        <br/>
        {t("The secret might have been compromised and read by someone else. You should contact the sender and request a new secret.")}
      </p>
      <h4 className={'c-gray fs-20'}>{t("Broken link")}</h4>
      <p className={'c-light-gray fs-16'}>
        {t("The link must match perfectly in order for the decryption to work, it might be missing some magic digits.")}
      </p>
      <h4 className={'c-gray fs-20'}>{t("Expired")}</h4>
      <p className={'c-light-gray fs-16'}>
        {t("No secret last forever. All stored secrets will expires and self destruct automatically after two weeks.")}
      </p>
    </div>
  ) : null;
};
export default Error;
