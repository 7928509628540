import * as React from 'react';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Alert, Button, FormGroup, Input, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Form = (
  props: {
    readonly display: boolean;
    readonly uuid: string | undefined;
    readonly prefix: string;
  } & React.HTMLAttributes<HTMLElement>,
) => {
  const [password, setPassword] = useState('');
  const [redirect, setRedirect] = useState(false);
  const { t } = useTranslation();

  const doRedirect = () => {
    if (password) {
      setRedirect(true);
    }
  };

  if (redirect) {
    return <Redirect to={`/${props.prefix}/${props.uuid}/${password}`} />;
  }
  return props.display ? (
    <div>
      <Alert color="warning" className="no-border-r">
        {t(
          'Remember that the secret can only be opened or downloaded once!',
        )}
      </Alert>
      <FormGroup className={'secret-result'}>
        <Label className={'c-light-gray fs-16'}>{t("A decryption key is required, please enter it below")}</Label>
        <Input
          type="text"
          autoFocus={true}
          placeholder={t("Decryption Key")}
          value={password}
          className={'input-with-border c-light-gray fs-14'}
          onChange={e => setPassword(e.target.value)}
        />
      </FormGroup>
      <div className="clearfix">
        <div className="float-right">
          <Button color={'primary'} size="lg" className={'b-blue no-border-r fs-14 text-uppercase'}
                  onClick={doRedirect} style={{padding: '15px 32px'}}>
            {t("Decrypt Secret")}
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};
export default Form;
