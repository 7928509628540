import * as React from 'react';
import { Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Features = () => {
  const { t } = useTranslation();
  return (
    <Container className="features bg-features">
      <Row>
        <Feature title={t("End-to-end Encryption")} src="security.svg" alt="Security">
          {t("Encryption and decryption are being made locally in the browser. The decryption key is never stored with us.")}
        </Feature>
        <Feature title={t("Self-destruction")} src="share.svg" alt="Sharing">
          {t("Secrets have a maximum 2 week lifetime and are destroyed on first open or when it expires.")}
        </Feature>
        <Feature title={t("Highly secure")} src="account.svg" alt="Account">
          {t("Various security measures are in place for the most secure experience.")}
        </Feature>
      </Row>
    </Container>
  );
};

const Feature = (
  props: {
    readonly title: string;
    readonly src: string;
    readonly alt: string;
  } & React.HTMLAttributes<HTMLElement>,
) => {
  return (
    <div className="col-lg-4 col-sm-6 col-md-6">
      <div className="feature-box">
        <div className="feature-img-icon">
          <img alt={props.alt} src={props.src} />
        </div>
        <div className="feature-inner">
          <h4 className="fs-20 c-gray-dark">{props.title}</h4>
          <p className="fs-14 c-light-gray-3 m-b-0">{props.children}</p>
        </div>
      </div>
    </div>
  );
};
export default Features;
