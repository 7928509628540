import * as React from 'react';
import { useState } from 'react';
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label, Tooltip,
} from 'reactstrap';
import Result from './Result';
import { encryptMessage, postSecret, randomString } from './utils';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

const Create = () => {
  const [error, setError] = useState('');
  const [secret, setSecret] = useState('');
  const [exclusive, setExclusive] = useState(true);
  const [loading, setLoading] = useState(false);
  const [uuid, setUUID] = useState('');
  const [password, setPassword] = useState('');

  const [reset, setReset] = useState(false);

  const { t } = useTranslation();

  const submit = async () => {
    if (!secret) {
      return;
    }
    setLoading(true);
    setError('');
    try {
      const pw = randomString();
      const { data, status } = await postSecret({
        message: await encryptMessage(secret, pw),
        exclusive: exclusive,
      });
      if (status !== 200) {
        setError(data.message);
      } else {
        setUUID(data.message);
        setPassword(pw);
      }
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  };

  if (reset) {
    setSecret('');
    setUUID('');
    setPassword('');
    setExclusive(true);
    setReset(false);
  }

  return (
    <div>
      {uuid ? (
        <Result uuid={uuid} password={password} prefix="s" actions={{setReset}}/>
      ) : (
        <div>
          <div className="clearfix">
            <h1 className="float-left c-gray fs-32">{t('Share Your Secrets Securely with Magebit!')}</h1>
            <div className="shield title-icon float-right">
              <img alt="Magebit Secrets" src="shield.svg"/>
            </div>
          </div>
          <p className="lead c-light-gray fs-16">
            {t('Magebit secrets are created to reduce the amount of clear text passwords stored in email and chat conversations by encrypting and generating a short lived link which can only be viewed once.')}
          </p>
          <p className="lead c-light-gray fs-16">
            {t('The secret will automatically self-destroy after the first open or in 2 weeks if not opened.')}
          </p>
          <Error message={error} onClick={() => setError('')}/>
          <Form>
            <FormGroup>
              <Label className="text-uppercase c-blue fs-12 l-space-2">{t('Secret message')}</Label>
              <Input
                type="textarea"
                name="secret"
                rows="8"
                autoFocus={true}
                placeholder={t('Your secret message...')}
                onChange={e => setSecret(e.target.value)}
                value={secret}
                className="input-with-border fs-14"
              />
            </FormGroup>
            <div className="clearfix">
              <div className="upload-file-container">
                <NavLink to={'/upload'}>Upload file instead</NavLink>
              </div>
              <div className="secret-submit-container">
                <MagebitExclusively setExclusive={setExclusive} exclusive={exclusive}/>
                <Button
                  disabled={loading}
                  color="primary"
                  size="lg"
                  className="fs-14 text-uppercase no-focus-shadow b-blue no-border-r encrypt-message"
                  style={{padding: '15px 32px'}}
                  onClick={() => submit()}
                >
                  {loading ? (
                    <span>{t('Encrypting message...')}</span>
                  ) : (
                    <span>{t('Encrypt Message')}</span>
                  )}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export const MagebitExclusively = (
  props: {
    readonly exclusive: boolean;
    readonly setExclusive: React.Dispatch<React.SetStateAction<boolean>>;
  } & React.HTMLAttributes<HTMLElement>,
) => {
  const { t } = useTranslation();
  const { exclusive, setExclusive } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <FormGroup className="d-inline-block m-r-20 exclusive-share">
      <Input
        id={'exclusive'}
        type="checkbox"
        onChange={() => setExclusive(!exclusive)}
        checked={exclusive}
      />
      <Label for={'exclusive'} className="fs-14 m-r-5 c-light-gray">{t("Share with Magebit exclusively")}</Label>
      <span className="l-h-20 align-vm">
        <FontAwesomeIcon
            id="mb-exclusive"
            color="#8B8B8B"
            style={{opacity: '0.5'}}
            size="1x"
            icon={faInfoCircle}
        />{' '}
      </span>
      <Tooltip placement="left" isOpen={tooltipOpen} target="mb-exclusive" toggle={toggle}>
        Only employees connected to internal Magebit network will be able to open and view encrypted messages or files
      </Tooltip>
    </FormGroup>
  );
};

export const Error = (
  props: { readonly message: string } & React.HTMLAttributes<HTMLElement>,
) =>
  props.message ? (
    <Alert color="danger" className="no-border-r" {...props}>
      {props.message}
    </Alert>
  ) : null;

export default Create;
