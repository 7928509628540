import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Error from './Error';
import Form from './Form';
import { decryptMessage } from './utils';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button } from 'reactstrap';
import Clipboard from 'clipboard';
import { Redirect } from 'react-router-dom';

const DisplaySecret = () => {
  const [loading, setLoading] = useState(false);
  const [error, showError] = useState(false);
  const [secret, setSecret] = useState('');
  const { key, password } = useParams();
  const { t } = useTranslation();

  const decrypt = useCallback(async () => {
    if (!password) {
      return;
    }
    setLoading(true);
    const url = process.env.REACT_APP_BACKEND_URL
      ? `${process.env.REACT_APP_BACKEND_URL}/secret`
      : '/secret';
    try {
      const request = await fetch(`${url}/${key}`);
      if (request.status === 200) {
        const data = await request.json();
        const r = await decryptMessage(data.message, password, 'utf8');
        setSecret(r.data as string);
        setLoading(false);
        return;
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    showError(true);
  }, [password, key]);

  useEffect(() => {
    decrypt();
  }, [decrypt]);

  return (
    <div>
      {loading && (
        <h3>
          {t(
            'Fetching from database and decrypting in browser, please hold...',
          )}
        </h3>
      )}
      <Error display={error} />
      <Secret secret={secret} />
      <Form display={!password} uuid={key} prefix="s" />
    </div>
  );
};

const Secret = (
  props: { readonly secret: string } & React.HTMLAttributes<HTMLElement>,
) => {
  const [redirect, setRedirect] = useState(false);
  const { t } = useTranslation();
  new Clipboard('#copy-b', {
    target: () => document.getElementById('pre') as Element,
  });

  if (redirect) {
    return <Redirect to={'/'}/>
  }

  return props.secret ? (
    <div>
      <div className="clearfix">
        <h1 className="float-left c-gray fs-32">{t('Decrypted Message')}</h1>
        <div className="letter title-icon float-right">
          <img alt="Magebit Secrets" src="letter.svg"/>
        </div>
      </div>
      <Alert color="warning" className={'c-light-gray no-border-r'}>
        {t('This secret will not be viewable again, make sure to save it now!')}
      </Alert>
      <pre id="pre" className={'decrypted-message input-with-border c-gray-dark fs-14 b-white'}>{props.secret}</pre>
      <Button id="copy-b" color="primary" className="copy-secret-button no-focus-shadow" style={{padding: '10px 17px'}}>
        {t('Copy')} <FontAwesomeIcon icon={faClone}/>
      </Button>
      <div className={'text-right'} style={{marginTop: '34px'}}>
        <Button
          color="secondary"
          size="lg"
          className="fs-14 text-uppercase btn-gray-2 no-border-r encrypt-message"
          style={{padding: '15px 32px'}}
          onClick={() => setRedirect(true)}
        >
          <span>{t('Create new message')}</span>
        </Button>
      </div>
    </div>
  ) : null;
};

export default DisplaySecret;
