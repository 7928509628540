import * as React from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import { Container, Navbar, NavbarBrand } from 'reactstrap';

import './App.scss';
import Create from './Create';
import DisplaySecret from './DisplaySecret';
import Download from './Download';
import Features from './Features';
import Upload from './Upload';
import { useTranslation } from 'react-i18next';

class App extends React.Component {
  public render() {
    return (
      <Router>
        <Navbar expand="md" className="header-logo">
          <Container>
            <NavbarBrand href="/">
              <img alt="Magebit Secrets" src="magebit.svg" />
            </NavbarBrand>
          </Container>
        </Navbar>
        <Container className="margin">
          <Routes />
        </Container>
        <Features />
        <Attribution />
      </Router>
    );
  }
}

const Routes = () => {
  return (
    <div>
      <Route path="/" exact={true} component={Create} />
      <Route path="/upload" exact={true} component={Upload} />
      <Route exact={true} path="/s/:key/:password" component={DisplaySecret} />
      <Route exact={true} path="/s/:key" component={DisplaySecret} />
      <Route exact={true} path="/f/:key/:password" component={Download} />
      <Route exact={true} path="/f/:key" component={Download} />
    </div>
  );
};

const Attribution = () => {
const { t } = useTranslation();
  return (
    <div className="footer-container">
      <Container className="text-center m-t-20">
        <div className="text-muted small footer">
          {t("For any questions or issues contact")} <a href="mailto:info@magebit.com">info@magebit.com</a>
        </div>
      </Container>
    </div>
  );
};

export default App;
