import { faClone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Clipboard from 'clipboard';
import * as React from 'react';
import { Alert, Button, FormGroup, Input, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Result = (
  props: {
    readonly uuid: string;
    readonly password: string;
    readonly prefix: string;
    readonly actions: { setReset: any };
  } & React.HTMLAttributes<HTMLElement>,
) => {
  const { uuid, password, prefix, actions } = props;
  const { setReset } = actions;
  const base = `${window.location.protocol}//${window.location.host}/#/${prefix}`;
  const link = `${base}/${uuid}`;
  const { t } = useTranslation();

  new Clipboard(`#copy-text`, {
    text: () => `Secret stored in: ${link}\nDecryption key: ${password}\n`,
  });

  return (
    <div className={'text-left'}>
      <div className="clearfix">
        <h1 className="float-left c-gray fs-32">{t('Secret securely stored')}</h1>
        <div className="folder title-icon float-right">
          <img alt="Magebit Secrets" src="folder.svg"/>
        </div>
      </div>
      <p className={'c-light-gray fs-16'}>
        {t('The secret will automatically self-destroy after the first open or in 2 weeks if not opened.')}
        <br/>
        {t('The cautious should send the decryption key in a separate communication channel.')}
      </p>
      <CopyField name="full" label={t('Link')} value={link} />
      <CopyField name="key" label={t('Decryption Key')} value={password} />
      <Alert color="warning" className="no-border-r">
        {t(
            'Remember that the secret can only be opened or downloaded once so do not open the link yourself.',
        )}
      </Alert>
      <div className={'text-right'}>
        <Button
            color="secondary"
            size="lg"
            id="copy-text"
            className="fs-14 text-uppercase btn-gray-2 no-border-r"
            style={{padding: '15px 32px', marginRight: '10px'}}
        >
            <span>{t('Copy instructions')}</span>
        </Button>
        <Button
          color="secondary"
          size="lg"
          className="fs-14 text-uppercase btn-gray-2 no-border-r encrypt-message"
          style={{padding: '15px 32px'}}
          onClick={() => setReset(true)}
        >
          <span>{prefix === 'f' ? t('Upload new file') : t('Create new message')}</span>
        </Button>
      </div>
    </div>
  );
};

const CopyField = (
  props: {
    readonly label: string;
    readonly name: string;
    readonly value: string;
  } & React.HTMLAttributes<HTMLElement>,
) => {
  new Clipboard(`#${props.name}-b`, {
    target: () => document.getElementById(`${props.name}-i`) as Element,
  });

  return (
    <FormGroup className={'secret-result'}>
      <Label className={'text-uppercase c-blue fs-12 l-space-2'}>{props.label}</Label>
      <div className="input-group mb-3">
        <Input readOnly={true} id={`${props.name}-i`} value={props.value}
               className={'no-focus-shadow c-light-gray fs-14 input-with-border'}/>
        <div className="input-group-append">
          <Button color="primary" id={`${props.name}-b`} className="b-blue no-focus-shadow l-h-1">
            {' '}
            <FontAwesomeIcon icon={faClone} />
          </Button>
        </div>
      </div>
    </FormGroup>
  );
};

export default Result;
